/**
 * Node modules
 */
import { IonAlert } from '@ionic/react';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import BitHapticButton from '../HapticButton';
import * as Styled from '../../Styled';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import styled from 'styled-components';

/**
 * Styled components
 *  NOTE: The following styled components should ideally belong to the proper component folder.
 *  However, for a quick fix, they are added here for convenience.
 */
const TempDiv = styled.div`
  img:not(:first-child) {
    margin-left: 16px;
  }
  img{
    border-radius: 8px;
  }
`;

/**
 * Functional components
 */

const BitSelectDrawSystemGameTypes = (props) => {
  const {
    customerSystemGameTypes,
    drawSystemGameTypes,
    mode,
    selectedDrawSystemGameTypes,
    setSelectedDrawSystemGameTypes,
  } = props;
  const translate = useTranslate();
  const [isActionSheetOpen, setIsActionSheetOpen] = React.useState(false);
  const handleDismiss = React.useCallback(() => setIsActionSheetOpen(false), [setIsActionSheetOpen]);
  const handleClick = React.useCallback(() => setIsActionSheetOpen(true), [setIsActionSheetOpen]);
  const filteredSystemGameTypes = React.useMemo(() => {
    const list = [];
    const customerGameTypeIdSet = new Set(_.map(customerSystemGameTypes, 'systemGameTypeId'));
    for (let i = 0; i < drawSystemGameTypes.length; i += 1) {
      const { systemGameTypeId } = drawSystemGameTypes[i];
      if (customerGameTypeIdSet.has(systemGameTypeId)) {
        list.push(drawSystemGameTypes[i]);
      }
    }
    return list;
  }, [customerSystemGameTypes, drawSystemGameTypes]);
  const inputs = React.useMemo(() => {
    const components = [];
    const set = new Set();
    for (let i = 0; i < selectedDrawSystemGameTypes.length; i += 1) {
      set.add(selectedDrawSystemGameTypes[i].systemGameTypeId);
    }
    for (let i = 0; i < filteredSystemGameTypes.length; i += 1) {
      const {
        alias,
        systemGameTypeId,
      } = filteredSystemGameTypes[i];
      components.push({
        checked: set.has(systemGameTypeId),
        label: translate(`display.alias.${alias}`),
        name: alias,
        type: 'checkbox',
        value: filteredSystemGameTypes[i],
      });
    }
    return components;
  }, [filteredSystemGameTypes, selectedDrawSystemGameTypes, translate]);
  const buttons = [{
    handler: (alertData) => {
      setSelectedDrawSystemGameTypes(alertData);
    },
    text: translate('label.okay'),
  }];
  React.useEffect(() => {
    setSelectedDrawSystemGameTypes((previousSelectedDrawSystemGameTypes) => {
      const reselectedDrawSystemGameTypes = [];
      if (filteredSystemGameTypes.length > 1) {
        const selectedDrawSystemGameTypeCodeSet = new Set(_.map(previousSelectedDrawSystemGameTypes, 'code'));
        _.each(filteredSystemGameTypes, (drawSystemGameType) => {
          if (selectedDrawSystemGameTypeCodeSet.has(drawSystemGameType.code)) {
            reselectedDrawSystemGameTypes.push(drawSystemGameType);
          }
        });
        if (reselectedDrawSystemGameTypes.length === 0) {
          setIsActionSheetOpen(true);
        }
        return reselectedDrawSystemGameTypes;
      }
      const selectedDrawSystemGameTypeCodeSet = new Set(_.map(previousSelectedDrawSystemGameTypes, 'code'));

      _.each(filteredSystemGameTypes, (drawSystemGameType) => {
        if (selectedDrawSystemGameTypeCodeSet.has(drawSystemGameType.code)) {
          reselectedDrawSystemGameTypes.push(drawSystemGameType);
        }
      });
      return reselectedDrawSystemGameTypes;
    });
    if (filteredSystemGameTypes.length === 1) {
      setSelectedDrawSystemGameTypes([filteredSystemGameTypes[0]]);
    }
  }, [filteredSystemGameTypes, setSelectedDrawSystemGameTypes]);
  const selectedDrawSystemGameTypeIdSet = React.useMemo(() => new Set(_.map(selectedDrawSystemGameTypes, 'objectId')), [selectedDrawSystemGameTypes]);
  return (
    <React.Fragment>
      <IonAlert
        backdropDismiss={false}
        buttons={buttons}
        header={translate('title.game-types')}
        inputs={inputs}
        isOpen={isActionSheetOpen}
        onDidDismiss={handleDismiss}
      />
      <BitHapticButton
        disabled={drawSystemGameTypes.length === 0}
        onClick={handleClick}
      >
        {mode === 'command-desktop' && (
          <TempDiv>
            {drawSystemGameTypes.map(drawSystemGameType => (
              <Styled.Image
                mode={!selectedDrawSystemGameTypeIdSet.has(drawSystemGameType.objectId) ? 'faded' : 'default'}
                key={drawSystemGameType.objectId}
                src={images[drawSystemGameType.alias.replace(/\s/g, '')]}
              />
            ))}
          </TempDiv>
        )}
        {mode === 'command' && (
          <React.Fragment>
            <Styled.Division name="division">
              <Styled.Image
                minorScaledHeight={7}
                minorScaledWidth={7}
                src={images.Dice}
              />
            </Styled.Division>
            <TempDiv>
              {drawSystemGameTypes.map(drawSystemGameType => (
                <Styled.Image
                  mode={!selectedDrawSystemGameTypeIdSet.has(drawSystemGameType.objectId) ? 'faded' : 'default'}
                  key={drawSystemGameType.objectId}
                  src={images[drawSystemGameType.alias.replace(/\s/g, '')]}
                />
              ))}
            </TempDiv>
          </React.Fragment>
        )}
        {!mode && (
          <Styled.Image
            minorScaledHeight={7}
            minorScaledWidth={7}
            src={images.Dice}
          />
        )}
      </BitHapticButton>
    </React.Fragment>
  );
};
BitSelectDrawSystemGameTypes.displayName = 'BitSelectDrawSystemGameTypes';
BitSelectDrawSystemGameTypes.propTypes = {
  drawSystemGameTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedDrawSystemGameTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSelectedDrawSystemGameTypes: PropTypes.func.isRequired,
};
export default React.memo(BitSelectDrawSystemGameTypes);
