/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

/**
 * Components
 */
import * as Styled from '../../Styled';
import BitLiveCategoryWindow from '../LiveWindowCategory';
import BitLivePrizeWindow from '../LiveWindowPrize';
import BitLiveWindowReport from '../LiveWindowReport';
import { useTranslate } from '../../../../locales';

/**
 * Functional components
 */
const BitBoard = (props) => {
  const {
    items,
    type,
    stepGateMap,
    forwards,
    label,
    modelAndPrizeForEntries={},
    setKeyForGrandTotal,
  } = props;
  const [childrenForActive, setChildrenForActive] = React.useState({});
  const descriptions = React.useMemo(() => {
    const components = [];
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      const { key: objectId } = item;
      if (type === 'prize-volume') {
        components.push(
          <BitLivePrizeWindow
            forwards={forwards}
            stepGateMap={stepGateMap}
            modelAndPrizeForEntries={modelAndPrizeForEntries}
            setChildrenForActive={setChildrenForActive}
            key={objectId}
            {...item}
          />,
        );
      } else if (type === 'category-volume' || type === 'overall-volume') {
        components.push(
          <BitLiveCategoryWindow
            setKeyForGrandTotal={setKeyForGrandTotal}
            setChildrenForActive={setChildrenForActive}
            key={objectId}
            {...item}
          />,
        );
      } else if (type === 'request-volume') {
        components.push(
          <BitLiveCategoryWindow
            setChildrenForActive={setChildrenForActive}
            type="request"
            key={objectId}
            {...item}
          />,
        );
      } else if (type.startsWith('report')) {
        components.push(
          <BitLiveWindowReport
            key={objectId}
            type={type}
            {...item}
          />,
        );
      }
    }
    return (
      <Styled.DivisionChain>
        {components}
      </Styled.DivisionChain>
    );
  }, [forwards, items, setKeyForGrandTotal, type]);
  const isHidden = type.startsWith('report') ? false : _.every(Object.values(childrenForActive), value => !value);
  return (
    <Styled.DivisionBoard mode={isHidden ? 'hidden' : 'default'}>
      <Styled.DivisionBoardContent>
        <Styled.DivisionBoardHeader>
          <Styled.DivisionRow>
            <Styled.Span>
              {label}
            </Styled.Span>
          </Styled.DivisionRow>
        </Styled.DivisionBoardHeader>
        <Styled.DivisionWrap majorScaledOffset={2}>
          <Styled.DivisionRow>
            <Styled.DivisionBoardDescriptionItem>
              {descriptions}
            </Styled.DivisionBoardDescriptionItem>
          </Styled.DivisionRow>
        </Styled.DivisionWrap>
      </Styled.DivisionBoardContent>
    </Styled.DivisionBoard>
  );
};
BitBoard.defaultProps = {
  actions: null,
  isFirstBoot: false,
  isLoading: false,
  mode: 'default',
};
BitBoard.displayName = 'BitBoard';
BitBoard.propTypes = {
  actions: PropTypes.node,
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.node,
  ]).isRequired,
  header: PropTypes.node.isRequired,
  isFirstBoot: PropTypes.bool,
  isLoading: PropTypes.bool,
  mode: PropTypes.string,
};
export default React.memo(BitBoard);
