/**
 * Node modules
 */
import PropTypes from 'prop-types';
import React from 'react';
import { VariableSizeGrid } from 'react-window';
import _ from 'lodash';
import numeral from 'numeral';
import { Switch, Modal } from 'antd';
import { permissionIdentities } from '@betlytical/api-schema';
import { InputNumber } from 'antd';
import { Input } from 'antd';
/**
 * Assets
 */
import { images } from '../../../../assets';

/**
 * Components
 */
import BitBoard from '../Board';
import BitMask from '../Mask';
import BitPacket from '../Packet';
import * as Styled from '../../Styled';

/**
 * Helpers
 */
import {
  hooks,
  masks,
  utilities,
} from '../../../../helpers';

/**
 * Locales
 */
import { useTranslate } from '../../../../locales';
import VendorAntTag from '../../Vendor/Ant/Tag';
import VendorAntButton from '../../Vendor/Ant/Button';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';

/**
 * Styled components
 *  NOTE: The following styled components should ideally belong to the proper component folder.
 *  However, for a quick fix, they are added here for convenience.
 */
const CODE_TO_CATEGORY_ID = {
  '3B': 41,
  '3T': 54,
  '2B': 43,
  '2L': 44,
};
const CODE_TO_COLOR = {
  '3B': 'green',
  '3T': 'geekblue',
  '2B': 'gold',
  '2L': 'gold',
};
const TempDiv = styled.div`
  ${(props) => {
    if (props.indd > 0) {
      return 'margin-left: 8px';
    }
    return 'margin-left: 0px';
  }};
  ${(props) => {
    if (props.multiplier === 0) {
      return 'opacity: 0.2';
    }
  }};
  width: 95px;
`;

/**
 * Functional components
 */
const BitPacketBoards = (props) => {
  const {
    setChildrenForActive,
    entries,
    handleShowRecipes,
    forwards,
    stepGateMap,
    socket,
    systemGamePrizeAlias,
    systemGamePrizeCode,
    modelAndPrizeForEntries = {},
    numberLimit = {},
    systemGamePrizeId,
    systemGamePrizeNumberOfDigits,
  } = props;
  const searchInputRef = React.useRef(null);
  const [currentSearchValue, setCurrentSearchValue] = React.useState('');
  const [isPercentView, setIsPercentView] = React.useState(false);

  const [currentEntries, setCurrentEntries] = React.useState(_.orderBy(entries, ['quantity', 'number'], ['desc', 'asc']));
  React.useEffect(() => {
    setCurrentEntries(_.orderBy(entries, ['quantity', 'number'], ['desc', 'asc']));
  }, [entries]);
  const [currentRow, setCurrentRow] = React.useState(10);
  const onPercentChange = React.useCallback((e) => {
    setIsPercentView(e);
  }, []);
  const handleChangeMultiplier = React.useCallback((e) => {
    setCurrentMultiplier(e.target.value);
  }, []);

  // const { table } = xxx.response.payload;
  let parsed = null;
  try {
    parsed = JSON.parse(table);
  } catch (e) {

  }
  const saveMultipler = hooks.useActionBundle({
    actionType: 'SAVE_CUSTOMER_REQUEST_LIMITS_BY_SYSTEM_GAME_ID',
    by: 2,
    permissionIdentity: permissionIdentities.SAVE_CUSTOMER_REQUEST_LIMIT,
  });
  const {
    dispatch,
  } = saveMultipler;
  const forwardMap = React.useMemo(() => {
    const map = {
      marker: {},
      injector: {},
      marker_x: {},
      injector_x: {},
    };
    _.each(forwards, forward => {
      if (forward.customerId === '11ebb5a7-1310-18c2-87d6-96daf2bbf299') {
        map.marker[`${forward.number}#${forward.code}`] = Number(forward.quantity);
      }
      if (forward.customerId === '11ebb5a7-0c1c-76d2-aba1-15616b45c558') {
        map.injector[`${forward.number}#${forward.code}`] = Number(forward.quantity);
      }
      if (forward.customerId === '11ebb5a7-1310-18c2-87d6-96daf2bbf299') {
        map.marker_x[`${forward.number}#${forward.code}`] = Number(forward.quantityForwarded);
      }
      if (forward.customerId === '11ebb5a7-0c1c-76d2-aba1-15616b45c558') {
        map.injector_x[`${forward.number}#${forward.code}`] = Number(forward.quantityForwarded);
      }
    });
    return map;
  }, [forwards]);
  const onChange = React.useCallback((e) => {
    setCurrentRow(e);
  }, [setCurrentRow]);
  const handleSearchChange = React.useCallback((text) => {
    if (!text) {
      if (searchInputRef.current) {
        searchInputRef.current.value = '';
      }
      setCurrentSearchValue('');
    } else {
      setCurrentSearchValue(text);
    }
  }, []);
  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const [currentContext, setCurrentContext] = React.useState({});
  const [currentMultiplier, setCurrentMultiplier] = React.useState(0);
  const handleModalCancel = React.useCallback((entry) => {

    setIsModalOpened(false);
  }, []);
  const handleModalOk = React.useCallback((entry) => {
    dispatch({
      body: {
        number: currentContext.number,
        customerSystemTicketNumberPluginId: currentContext.customerSystemTicketNumberPlugins,
        elements: [{
          systemGameCategoryId: currentContext.systemGameCategoryId,
          multiplier: Number(currentMultiplier),
        }],
      },
      params: {
        systemGameId: currentContext.systemGameId,
        customerId: currentContext.customerId,
      },
    });
    setIsModalOpened(false);
  }, [dispatch, currentContext, currentMultiplier]);
  const handleTagClick = React.useCallback((entry) => {
    let number = entry.affectedNumber.replace('???', '');
    if (entry.systemGameCategoryCode === '3B' || entry.systemGameCategoryCode === '3T' || entry.systemGameCategoryCode === '2B' || entry.systemGameCategoryCode === '2L') {

      setIsModalOpened(true);
      let customerSystemTicketNumberPlugins = '11eb429f-6592-92b7-9b91-af72d6c6db5e';
      if (entry.systemGameCategoryCode === '2B' || entry.systemGameCategoryCode === '2L') {
        customerSystemTicketNumberPlugins = '11eb429f-6592-92b6-b65a-fa6a56c15ceb';
        number = number.substring(number.length - 2);
      }
      const elements = [{}];
      const customerId = '11eb2f5d-fe5c-06c0-ba24-8833dc9b64c9';
      const systemGameId = 2;
      const multiplier = entry.multiplier;
      const systemGameCategoryId = CODE_TO_CATEGORY_ID[entry.systemGameCategoryCode];
      setCurrentMultiplier(multiplier);
      setCurrentContext({
        number,
        customerSystemTicketNumberPlugins,
        customerId,
        systemGameId,
        multiplier,
        systemGameCategoryId,
        systemGameCategoryCode: entry.systemGameCategoryCode,
      });
    }
  }, [setCurrentContext, setIsModalOpened, setCurrentMultiplier]);
  const debouncedSearch = _.debounce(handleSearchChange, 500);
  const debouncedHandleSearchChange = React.useCallback(e => debouncedSearch(e.target.value), [debouncedSearch]);
  const handleSearchClear = React.useCallback(() => handleSearchChange(''), [handleSearchChange]);
  const filteredEntries = React.useMemo(() => {
    const list = [];
    if (currentSearchValue) {
      if (currentSearchValue.startsWith('*')) {
        const finalSearch = currentSearchValue.replace(/\D/g, '').split('').sort().join('');
        for (let i = 0; i < currentEntries.length; i += 1) {
          if (currentEntries[i].number.split('').sort().join('').replace(/\D/g, '').includes(finalSearch)) {
            list.push({
              rank: i + 1,
              ...currentEntries[i],
            });
          }
        }
      } else if (currentSearchValue.startsWith('x')) {
        const searches = new Set();
        for (let i = 0; i <= 9; i += 1) {
          searches.add(currentSearchValue.replace(/x/g, String(i)));
        }
        for (let i = 0; i < currentEntries.length; i += 1) {
          if (searches.has(currentEntries[i].number)) {
            list.push({
              rank: i + 1,
              ...currentEntries[i],
            });
          }
        }
      } else {
        for (let i = 0; i < currentEntries.length; i += 1) {
          if (currentEntries[i].number.replace(/\D/g, '').includes(currentSearchValue)) {
            list.push({
              rank: i + 1,
              ...currentEntries[i],
            });
          }
        }
      }

      return list;
    }
    return currentEntries;
  }, [currentSearchValue, currentEntries]);
  const translate = useTranslate();
  const { length } = currentEntries;
  React.useEffect(() => {
    setChildrenForActive((previousChildrenForActive) => {
      const cloned = { ...previousChildrenForActive };
      cloned[systemGamePrizeId] = length !== 0;
      return cloned;
    });
  }, [length, setChildrenForActive, systemGamePrizeId]);
  React.useEffect(() => {
    if (socket && socket.id) {
      socket.emit('ready', systemGamePrizeId);
    }
  }, [socket, socket.id, systemGamePrizeId]);
  React.useEffect(() => {
    if (socket) {
      socket.on(`data-${systemGamePrizeId}`, (data) => {
        utilities.digestEntries({
          data,
          setCurrentEntries,
        });
      });
    }
  }, [socket, systemGamePrizeId]);
  return React.useMemo(() => {
    const windowContent = (
      <Styled.DivisionWindow>
        <Styled.DivisionWindowHeader>
          <Styled.DivisionWindowHeaderLabel mode="rank">
            {translate('title.rank')}
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="number">
            {translate('title.number')}
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="risk">
            <Styled.Span>
              {translate('title.risk')}
            </Styled.Span>
            <Styled.Image
              minorScaledHeight={5}
              minorScaledWidth={5}
              src={images.Baht}
            />
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="amount">
            <Styled.Span>
              {translate('title.amount')}
            </Styled.Span>
            <Styled.Image
              minorScaledHeight={5}
              minorScaledWidth={5}
              src={images.Baht}
            />
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="tags">
            <Styled.Span>
              {translate('title.tags')}
            </Styled.Span>
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="quota">
            <Styled.Span>
              {translate('Quota')}
            </Styled.Span>
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="quota">
            <Styled.Span>
              {translate('Inject')}
            </Styled.Span>
          </Styled.DivisionWindowHeaderLabel>
          <Styled.DivisionWindowHeaderLabel mode="final">
            <Styled.Span>
              {translate('Final')}
            </Styled.Span>
          </Styled.DivisionWindowHeaderLabel>
        </Styled.DivisionWindowHeader>
        <VariableSizeGrid
          columnCount={8}
          columnWidth={columnIndex => [80, 100, 152, 132, 350, 100, 100, 200][columnIndex]}
          height={currentRow * 40}
          rowCount={filteredEntries.length}
          rowHeight={() => 40}
          width={490 + 350 + 100 + 100 + 200}
        >
          {(window) => {
            const {
              columnIndex,
              rowIndex,
              style,
            } = window;
            const {
              amount,
              number,
              rank,
              quantity,
            } = filteredEntries[rowIndex];
            let limit = (numberLimit[number] || numberLimit['-']) || null;
            if (number && number.length === 6) {
              limit = (numberLimit[number.substring(3, 6)] || numberLimit['-']) || null;
            }
            let overrideMultipler = null;

            // limitTable.AWindow
            const filled = limit ? quantity / limit : null;
            const bundle = {
              ...filteredEntries[rowIndex],
              systemGamePrizeAlias,
              systemGamePrizeCode,
              systemGamePrizeId,
              systemGamePrizeNumberOfDigits,
            };
            let tags = modelAndPrizeForEntries[`${number.replace('???', '')}#${systemGamePrizeId}`] || [];


            if (systemGamePrizeId === 24) {
              // let overrideMultipler3B = limitTable['A_3B'][Akey]
              // let overrideMultipler3T = limitTable['A_3T_0'][Akey]
              // let overrideMultipler2B = limitTable['D_2B'][Dkey]
              //
              // if (new Set(number.replace('???', '').split("")).size === 3) {
              //   overrideMultipler3T = limitTable['A_3T_0'][Akey]
              // } else if (new Set(number.replace('???', '').split("")).size === 2) {
              //   overrideMultipler3T = limitTable['A_3T_1'][Akey]
              // } else if (new Set(number.replace('???', '').split("")).size === 1) {
              //   overrideMultipler3T = limitTable['A_3T_2'][Akey]
              // }
              const systemGameCategoryCodeForMultiplier = {};
              _.each(tags, t => {
                systemGameCategoryCodeForMultiplier[t.systemGameCategoryCode] = t.multiplier;
              });
              const n = number.replace('???', '');
              const step = stepGateMap?.Amap[n];
              tags = [
                {
                  'affectedNumber': number,
                  'multiplier': (step ? step['3B'] : null) || systemGameCategoryCodeForMultiplier['3B'] || 0,
                  'systemGameCategoryAlias': '3B',
                  'systemGameCategoryCode': '3B',
                  'systemGamePrizeId': 24,
                },
                {
                  'affectedNumber': number,
                  'multiplier': (step ? step['3T'] : null) ||systemGameCategoryCodeForMultiplier['3T'] || 0,
                  'systemGameCategoryAlias': '3T',
                  'systemGameCategoryCode': '3T',
                  'systemGamePrizeId': 24,
                },
                {
                  'affectedNumber': number,
                  'multiplier': (step ? step['2B'] : null) || systemGameCategoryCodeForMultiplier['2B'] || 0,
                  'systemGameCategoryAlias': '2B',
                  'systemGameCategoryCode': '2B',
                  'systemGamePrizeId': 24,
                },
              ];
            }

            if (systemGamePrizeId === 29) {
              // let overrideMultipler2L = limitTable['D_2L'][Dkey]
              const step = stepGateMap?.Dmap[number];
              const systemGameCategoryCodeForMultiplier = {};
              _.each(tags, t => {
                systemGameCategoryCodeForMultiplier[t.systemGameCategoryCode] = t.multiplier;
              });
              tags = [
                {
                  'affectedNumber': number,
                  'multiplier': (step ? step['2L'] : null) || systemGameCategoryCodeForMultiplier['2L'] || 0,
                  'systemGameCategoryAlias': '2L',
                  'systemGameCategoryCode': '2L',
                  'systemGamePrizeId': 29,
                },
              ];
            }
            return (
              <Styled.DivisionWindowRow
                mode={(rowIndex + 1) % 2 === 0 ? 'even' : 'odd'}
                style={style}
                type="tags"
              >
                {columnIndex === 0 && (
                  <Styled.DivisionColumn>
                    <Styled.Span>
                      {rank || rowIndex + 1}
                    </Styled.Span>
                    {filled !== null && (
                      <Styled.DivisionlFill filled={filled}>
                        {isPercentView ? numeral(filled * 100).format('0.00') + '%' : numeral(limit).format('0.00a')}
                      </Styled.DivisionlFill>
                    )}
                  </Styled.DivisionColumn>
                )}
                {columnIndex === 1 && (
                  <Styled.DivisionTicketNumber
                    mode="link"
                    onClick={() => handleShowRecipes(bundle)}
                  >
                    {number.replace(/\D/g, '')}
                  </Styled.DivisionTicketNumber>
                )}
                {columnIndex === 2 && (
                  <Styled.SpanRightAlign>
                    {utilities.toLocaleString(quantity, 2)}
                  </Styled.SpanRightAlign>
                )}
                {columnIndex === 3 && (
                  <Styled.SpanRightAlign>
                    {utilities.toLocaleString(amount, 2)}
                  </Styled.SpanRightAlign>
                )}
                {columnIndex === 4 && (
                  <div style={{ display: 'flex' }}>
                    {tags.map((entry, indd) => {
                      const {
                        multiplier,
                        objectId,
                        systemGameCategoryAlias,
                        systemGameCategoryCode,
                      } = entry;
                      return (
                        <TempDiv indd={indd} multiplier={multiplier} systemGameCategoryCode={systemGameCategoryCode} onClick={() => handleTagClick(entry)}>
                          <VendorAntTag key={objectId} color={CODE_TO_COLOR[systemGameCategoryCode]}>
                            {translate(`display.alias.${systemGameCategoryCode}-${systemGameCategoryAlias}`)}
                            <Styled.Small>
                              {`x${multiplier}`}
                            </Styled.Small>
                          </VendorAntTag>
                        </TempDiv>
                      );
                    })}
                  </div>
                )}
                {columnIndex === 5 && (
                  <div style={{ display: 'flex' }}>
                    {utilities.toLocaleString((forwardMap.marker[`${number}#${systemGamePrizeCode}`] || 0) - (forwardMap.injector[`${number}#${systemGamePrizeCode}`] || 0))}
                  </div>
                )}
                {columnIndex === 6 && (
                  <div style={{ display: 'flex' }}>
                    {utilities.toLocaleString(forwardMap.injector[`${number}#${systemGamePrizeCode}`] || 0)}
                  </div>
                )}
                {columnIndex === 7 && (
                  <div style={{ display: 'flex' }}>
                    {utilities.toLocaleString(Number(quantity || 0) - (
                      (forwardMap.marker[`${number}#${systemGamePrizeCode}`] || 0) -
                      (forwardMap.injector[`${number}#${systemGamePrizeCode}`] || 0)) * (number.length === 2 ? 70 : 550), 2)}
                  </div>
                )}
              </Styled.DivisionWindowRow>
            );
          }}
        </VariableSizeGrid>
        <Styled.DivisionWindowFooter mode="tanked">
          <Styled.Span>
            {`${translate('label.entries')}: ${currentEntries.length.toLocaleString()}`}
          </Styled.Span>
        </Styled.DivisionWindowFooter>
      </Styled.DivisionWindow>
    );
    const searchClear = currentSearchValue !== undefined && (
      <Styled.DivisionSearchClear onClick={handleSearchClear}>
        <Styled.Icon.IonicClose />
      </Styled.DivisionSearchClear>
    );

    const inputNumber = <InputNumber min={1} max={1000} defaultValue={10} onChange={onChange} />;
    const title = (
      <>
        <Modal title="Multiplier Changer" open={isModalOpened} onOk={handleModalOk} onCancel={handleModalCancel}>
          <p><b>Number:</b> {currentContext.number}</p>
          <p><b>Code:</b> {currentContext.systemGameCategoryCode}</p>
          <p><b>Multiplier:</b> x{currentContext.multiplier} <Input onChange={handleChangeMultiplier} value={currentMultiplier} /></p>
        </Modal>
        <Styled.DivisionWindowTitle>
          <Styled.Division>
            {translate(`display.alias.${systemGamePrizeAlias.split(' ')[0]}`)}
            <div style={{ marginLeft: '8px' }}>
              {inputNumber}
            </div>
            <div style={{ marginLeft: '8px' }}>
              <Switch checked={isPercentView} onChange={onPercentChange} />
            </div>
          </Styled.Division>
          <Styled.DivisionSearch>
            <Styled.DivisionTableHandlerIcon>
              <Styled.Icon.IonicSearch />
            </Styled.DivisionTableHandlerIcon>
            <Styled.InputSearch
              onChange={debouncedHandleSearchChange}
              placeholder={`${translate('label.placeholder.search')}...`}
              ref={searchInputRef}
            />
            {searchClear}
          </Styled.DivisionSearch>
        </Styled.DivisionWindowTitle>
      </>
    );
    return (
      <Styled.DivisionChain mode={currentEntries.length === 0 ? 'hidden' : 'default'}>
        <BitPacket
          mode="window"
          title={title}
          windowContent={windowContent}
        />
      </Styled.DivisionChain>
    );
  }, [forwardMap, forwards, modelAndPrizeForEntries, stepGateMap, currentEntries, currentSearchValue, debouncedHandleSearchChange, filteredEntries, handleSearchClear, handleShowRecipes, numberLimit, systemGamePrizeAlias, systemGamePrizeCode, systemGamePrizeId, systemGamePrizeNumberOfDigits, translate]);
};
BitPacketBoards.displayName = 'BitPacketBoards';
BitPacketBoards.propTypes = {
  contentKey: PropTypes.string,
  dataType: PropTypes.string,
  handleShowRecipes: PropTypes.func,
  isInput: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  maskOptions: PropTypes.objectOf(PropTypes.any),
  mode: PropTypes.string,
  path: PropTypes.string,
  possibleLinkKeySet: PropTypes.instanceOf(Set),
  recommendedContentKey: PropTypes.string,
  referenceContentKey: PropTypes.string,
  setFieldValue: PropTypes.func,
  symbol: PropTypes.node,
  type: PropTypes.string,
  unit: PropTypes.node,
};
export default React.memo(BitPacketBoards);
